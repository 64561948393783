import { Injectable } from '@angular/core';
import { User } from '@wilson/interfaces';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { Observable, of, tap } from 'rxjs';
import { AccountService } from '../account.service';

@Injectable({
  providedIn: 'root',
})
export class UsersCacheService {
  @WilsonState<Record<string, User>>({})
  private users!: Record<string, User>;

  constructor(private accountService: AccountService) {}

  getUserFromCache(id: string): Observable<User> {
    const user = this.users[id];
    if (user) {
      return of(user);
    } else {
      return this.accountService.get(id).pipe(
        tap((user) => {
          this.users[user.id as string] = user;
        }),
      );
    }
  }

  clearUserCache(): void {
    this.users = {};
  }
}
